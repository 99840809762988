// These imports have no CSS output, but provide variables, mixins and helpers to aid in construction of
// a design cutup.
@import "imports/variables.scss";
@import "imports/functions.scss";
@import "imports/mixins.scss";
@import "imports/placeholders.scss";
@import "imports/font.scss";

// Box size all the things
// https://squizmap.squiz.net/imp-bp/6400
// http://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/
// == Start box-sizing reset ==
*,
*::before,
*::after {
    -webkit-box-sizing: inherit;
       -moz-box-sizing: inherit;
            box-sizing: inherit;
}
html {
    @include sq-border-box;
}
// == End box-sizing reset ==

/*
--------------------
Utilities
--------------------
*/

@import "imports/utilities.scss";

/*
--------------------
General
--------------------
*/

// Taken from HTML 5 BP
::-moz-selection {
    background: $content-selection-highlight;
    text-shadow: none;
}

::selection {
    background: $content-selection-highlight;
    text-shadow: none;
}

html,
body {
    height: 100%;
}
body {
    overflow-y: scroll;
    font-size: $base-font-scale + em;
    line-height: 1.4;
    padding: 0;
    margin: 0;
    background: $grey-200;
    font-family: $font-family-source-sans-pro !important;
}

img {
    max-width: 100%;
}

// Taken from Funnelback customer.css
* a,
.btn-link {
    color: black;
}

* a:hover,
* a:active,
* a:focus,
* .btn-link:hover,
* .btn-link:active,
* .btn-link:focus {
    color: #F9423A;
    transition: all 0.15s ease-out;
}


// Reset fonts for relevant elements (taken from bootstrap)
input,
button,
select,
textarea {
    font-family: 'Source Sans Pro', sans-serif !important;
    font-size: inherit;
    line-height: inherit;
}

// Overwriting Bootstrap
.text-muted {
    color: var(--col-text-primary) !important;
}

// IE10 Metro responsive
// Required for Windows 8 Metro split-screen snapping with IE10
// Source: http://timkadlec.com/2012/10/ie10-snap-mode-and-responsive-design/
@-ms-viewport{
    width: device-width;
}

// Print style that shouldn't belong to print.scss to avoid the css beautifier from killing
// the @ syntax inside media queries
@page {
    margin: 0.5cm;
}

input[type="search"]::-webkit-search-cancel-button {
    -webkit-appearance: none;
    height: 14px;
    width: 14px;
    border-radius: 50em;
    background: url("~src/images/close.svg")
        no-repeat 50% 50%;
    background-size: contain;
    opacity: 0;
    pointer-events: none;
}

input[type="search"]:focus::-webkit-search-cancel-button {
    opacity: 1;
    pointer-events: all;
}

// input[type="search"].dark::-webkit-search-cancel-button {
//     filter: invert(1);
// }

/*
--------------------
Modules
--------------------
*/
@import "customer.scss";
@import "../modules/_global/css/global.scss";
@import "../modules/search-autocomplete/css/global.scss";
@import "../modules/search-banner/css/global.scss";
@import "../modules/search-facets/css/global.scss";
@import "../modules/search-footer/css/global.scss";
@import "../modules/search-header/css/global.scss";
@import "../modules/search-pagination/css/global.scss";
@import "../modules/search-related/css/global.scss";
@import "../modules/search-results/css/global.scss";
@import "../modules/search-sort/css/global.scss";
@import "../modules/search-tabs/css/global.scss";
