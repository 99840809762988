/*
 * Mobile layout
 */

.search-tabs__wrapper.tabs {
    padding: 0;
    margin-top: 0;
    position: relative;
    
    .navbar-collapse {
        position: relative;
        overflow: auto;
        width: 100%;
        background-color: var(--col-bg-primary);
    }

    .navbar__tab-control {
        padding: 1rem;
        height: 84%;
        color: white;
        align-self: center;
        position: absolute;
        z-index: 1;
        top: 0;
        display: none;
        border: none;
        background: none;

        &--hidden {
            display: none;
        }

        &--left {
            left: 0;
            background-image: url("~src/images/chevron-left.svg"), linear-gradient(98deg, rgba(0, 15, 70, .95) 42.05%, rgba(9, 65,131, 0));
            background-position: center;
            background-repeat: no-repeat;
            background-size: 14px, 100%;
        }

        &--right {
            right: 0;
            background-image: url("~src/images/chevron-right.svg"), linear-gradient(270deg, rgba(0, 15, 70, .95) 37.5%, rgba(9, 65,131, 0));
            background-position: center;
            background-repeat: no-repeat;
            background-size: 14px, 100%;
        }
    }

    ul.nav-tab {
        background-color: #fff;
        margin: 0;
        padding: 0;
        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-start;
        background-color: var(--col-bg-primary);
        
        li {
            display: block;
            flex-grow: 1;
            margin-bottom: 0;
            margin-right: 0.25rem;
            margin-inline-start: 0;
            text-align: center;
            transition: background .15s ease;
            padding: 0.75rem 1rem;
            width: auto;

            a {
                border-radius: 0;
                display: inline-block;
                width: 100%;
                height: 100%;
                text-decoration: none;
                color: white;
                font-size: 16px;
                line-height: 24px;
                padding: 0;
                font-weight: 400;

                .search-facet-count {
                    opacity: 0.8;
                    font-weight: var(--fw-regular);
                    font-size: var(--fs-base-100);
                }
            }

            &:last-child {
                margin-right: 0;
                border-top: none;
                min-width: 7.5rem;

                a {

                    &:before {
                        content: url("~src/images/padlock-white.svg");
                        padding-right: 7px;
                        vertical-align: middle;
                    }

                    &:after {
                        content: var(--search-staff-hub-arrow);
                        padding-left: 7px;
                        vertical-align: middle;
                    }
                }

            }

            &:hover a {
                color: var(--col-link-light-active);
                text-decoration: underline;
            }
        }

        li.active {
            display: block;
            border-bottom: 5px solid var(--col-link-light-active);
            
            a {
                
                color: var(--col-link-light-active);
                font-weight: var(--fw-semibold);
            }

            &:hover a {
                text-decoration: none;
            }

        }

    }
}

/*
 * Tablet layout
 */

@media screen and (min-width: $tablet){
    .search-tabs__wrapper.tabs {
    
        ul.nav-tab {
            border: none;
            flex-flow: row nowrap;
            padding-left: 1.5rem;
            padding-right: 1.5rem;
            max-width: 63rem;
            gap: 2.5rem;

            li {
                padding: 1.25rem 0.5rem;

                a {
                    padding: 0px;
                }

                &:last-child {
                    margin-left: auto;
                }

            }
        }
    }
}

@media (min-width: 992px) {
    .search-tabs__wrapper.tabs ul.nav-tab {
        padding-left: 15px;

   }
}

/*
 * Desktop layout
 */

@media screen and (min-width: $desktop){
    .search-tabs__wrapper.tabs {
        // margin-bottom: 36px;
        
        ul.nav-tab {
            // padding-left: calc(( 100% - 1110px ) / 2 );
            // padding-right: calc(( 100% - 1110px ) / 2 );
            padding-left: 1.5rem;
            padding-right: 1.5rem;

            li {
                flex: none;

                a {
                    font-size: var(--fs-level-6);
                }

                &:last-child  {
                    a {
                        --search-staff-hub-arrow: url("~src/images/chevron-right-tael--tabs.svg");
                    }

                }
            }
        }
        
    }
}

@media screen and (min-width: 1360px) {
    .search-tabs__wrapper.tabs {

        ul.nav-tab {
            max-width: 1134px;
            padding-left: 27px;
            padding-right: 15px;
            line-height: 27px;
        }
    }
}

 