/*
 * Sort and Limit CSS
 */

 .desktop-hide {
     .search-sort__wrapper {

        .dropdown-menu {
            position: absolute;
            transform: translate3d(15px, 124px, 0px);
            top: 0px;
            left: 0px;
            will-change: transform;
            right: 65px;

            li {
                margin-inline: 0;
                width: 100%;
                margin-block-end: 0.75rem;
            }
        }
     }
 }

 .mobile-hide {
    .search-sort__wrapper {

       .dropdown-menu {
            position: absolute;
            transform: translate3d(600px, 43px, 0px);
            top: 0px;
            left: 0px;
            will-change: transform;
            right: 651px;
            

            li {
                margin-inline: 0;
            }
        }
    }
}

.search-sort__wrapper {

    .dropdown-menu {
        padding-inline: 0;
        padding-block-end: 0.5rem;
        border: 1px solid var(--col-heritage-100);
        border-radius: 0 0 0.25rem 0.25rem;
    }

    .float-right {
        min-width: 232px;
        

        // &:first-child {
        //     min-width: 118px;
        //     // margin-left: 18px;
        // }

        .dropdown-item {
            color: var(--col-text-primary);
            font-weight: var(--fw-regular);
            text-decoration: none;

            &:where(:hover,:focus) {
                background-color: var(--col-sage-light-50);
            }
        }
    }

    button {
        padding: 7px 18px 7px 9px;
        border-radius: 3px;
        // box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
        // width: 110%;
        font-size: 18px;
        // letter-spacing: -0.5px;
        text-align: left;
        margin-bottom: 16px;
        position: relative;
        border: 1px solid #000B34;
        color: var(--col-text-primary);
        width: 100%;

        &::before {
            content: '';
            position: absolute;
            right: 12px;
            background-color: var(--col-text-primary);
            width: 1px;
            height: 100%;
            bottom: 0;
            right: 35px;
        }

        &:after {
            content: url("~src/images/chevron-navy.svg");
            position: absolute;
            right: 12px;
            top: 10px;
            transform: rotate(180deg);
        }

        &:hover,
        &:focus {
            background-color: var(--col-sage-light-50);
            border-color: var(--col-text-primary);
        }

        &:focus {
            outline: 5px auto -webkit-focus-ring-color;
        }

    }
}


.search-facets__wrapper {
    .search-sort__wrapper {
        display: flex;
        flex-direction: row-reverse;
        flex-wrap: wrap;
        gap: 18px;

        .float-right {
            flex: 1;
        }
    }
}

@media screen and (min-width: $desktop) {

    .search-sort__wrapper {

        .float-right {
            min-width: 12.95rem;

            &:first-child {
                min-width: 15rem;
                margin-left: 18px;
            }
        }
    }

}

@media screen and (min-width: 1360px) {

    .mobile-hide {
        .search-sort__wrapper {
    
           .dropdown-menu {
                right: 620px;
            }
        }
    }

}