/*
 * Mobile layout
 */

body {
    z-index: 1;
}

.mobile-hide {
    display: none;
}

.blanket {
    position: absolute;
}


.container {
    max-width: none;
}

@media screen and (min-width: 900px) {
    .mobile-only {
        display: none; }

    nav.desktop-hide {
        display: none;
    }
}

/*
 * Tablet layout
 */

@media screen and (min-width: $tablet){
    body {
        z-index: 2;
    }
}


/*
 * Desktop layout
 */

@media screen and (min-width: $desktop){
    body {
        z-index: 3;
    }

    .desktop-hide {
        display: none;
    }
    
    .mobile-hide {
        display: block;
    }

    .container {
        max-width: 1140px;
        padding-inline: 1.6875rem;
    }

}
