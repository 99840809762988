/*
 * Mobile layout
 */

.search-facet__button {
    background: transparent;
    border: none;
    position: fixed;
    right: 20px;
    bottom: 30px;
    z-index: 10;

    &:before {
        // content: url(../img/filter-icon.svg);
        content: url("~src/images/filter-icon.png");
    }

    #search-refined-by-notification {
        background-color: #e42313;
        border-radius: 100%;
        color: #fff;
        line-height: 1.25rem;
        max-width: 20px;
        padding: 0 6px;
        position: absolute;
        right: -2px;
        top: -2px;
        z-index: 1;
        font-size: 0.875rem;
        font-weight: 500;
    }
}

// .search-facet__btn[type=button] {
//     -webkit-appearance: none;
// }

ol.list-unstyled {
    max-width: none;
    padding: 0;

    &--people {

        .search-result__card {

            .card-header {
                padding: 0;
            }

        }



    }
}

.search-results {
    // background-color: #f3f5f9!important;
    padding-top: 19px;
    padding-bottom: 60px !important;
    position: relative;

    .fa-file-pdf-o {
        padding-top: 0.5rem;
    }
    
    .icon {
        width: 18px;
        height: 18px;
        margin: 0;
        vertical-align: sub;
        margin-inline-end: 0.5rem;
        margin-right: 5px;
    }

    .row.flex-row-reverse {
        justify-content: space-between;
    }

    .search-tier {
        h3 {
            letter-spacing: -0.5px;
            color: $blue-400;
            font-size: 20px;
            font-weight: 600;
        }
    }

    li.search-result {
        margin: 0;
        padding-top: 1.25rem;
        border-top: solid 1px var(--col-grey-light-50);
        padding-block-end: 0;
        max-width: none;
        width: 100%;

        &:last-child .search-result__card {
            border-bottom: 0;
        }
    }

    .search-counts {
        font-size: 16px;
        line-height: 24px;
        letter-spacing: -0.5px;
        color: var(--col-grey-dark-100);
        margin-bottom: 1rem;
    }

    li.search-counts {
        font-size: 18px;
        line-height: 23px;
        letter-spacing: normal;
        padding: 0;
        margin: 0;

        p {
            padding: 0;
            margin: 0;            
        }
    }

    .list-inline {
        font-size: 15px;
        margin-bottom: 0;
        padding-top: 1.25rem;
        // border-top: solid 1px $grey-mid-0;
        margin: 0;
        max-width: none;

        &:last-child .search-result__card {
            border-bottom: 0;
        }

    }

    .search-counts {
        font-size: 16px;
        line-height: normal;
        letter-spacing: -0.5px;
        // color: #3b3b3b;
        margin-bottom: 16px;

    }

    .list-inline {
        font-size: 16px;
        margin-bottom: 0;
        padding-inline: 0;
        padding-block-end: 0;
        

        .list-inline-item {
            display: block;
            margin-bottom: 8px;
            margin-inline-start: 0;
            letter-spacing: -0.5px;
            padding-block-end: 0;


            .badge {
                padding: 8px 30px 8px 8px;
                background-color: var(--col-grey-light-25, #f1f1f1);
                font-weight: 400;
                font-size: 14px;
                color: var(--col-link-100);
                position: relative;
                border-radius: 3px;
                text-decoration: none;

                &:after {
                    position: absolute;
                    content: '';
                    background-image: url("~src/images/close-navy.svg");
                    background-repeat: no-repeat;
                    height: 10px;
                    width: 10px;
                    background-size: contain;
                    right: 12px;
                    top: 50%;
                    transform: translateY(-50%);

                }


                &:hover {
                    background-color: #000F46;
                    color: white;

                    &::after {
                        background-image: url("~src/images/close-white.svg");
                    }
                }
            }

        }

    }

    .recommended-result {
        // margin-top: 38px;

        .card-subtitle-recommended {
            text-transform: capitalize;
            font-weight: 700;
            font-size: 18px;
            line-height: 21px;
            margin-bottom: 16px;
        }

        .search-result__card {
            padding-top: 1.25rem;
            border-top: solid 1px var(--col-grey-light-50);

            h4 {
                padding-top: 8px;
            }

            .card-subtitle {
                color: $grey-mid-1;
                font-size: 16px;
                font-weight: 400;
                line-height: 24px;
                padding-top: 8px;
            }

            &--duration,
            &--location {
                display: block;
                padding-right: 16px;
                font-size: var(--fs-level-7);
                color: var(--col-text-primary);
                text-transform: uppercase;
                font-weight: var(--fw-semibold);
                letter-spacing: 0.98px;
            }

            // &--duration:before {
            //   content: url("~src/images/hourglass.svg");
            //   vertical-align: sub;
            //   padding-right: 8px;
            //   padding-left: 5px;
            // }

            &--location {
                padding-bottom: 1rem;
                padding-left: 0 !important;

                // &:before {
                //     //   content: url(../img/location-icon.svg);
                //     content: url("~src/images/location-navy.svg");
                //     vertical-align: sub;
                //     padding-right: 8px;
                // }


            }



        }

    }

    .card-subtitle-all-results {
        text-transform: capitalize;
        font-weight: 700;
        font-size: 18px;
        line-height: 21px;
        margin-bottom: 16px;
        padding-top: 92px;

    }


    .search-result__card {
        border: none;
        border-radius: 3px;
        // box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.22);


        .search-result__image--wrapper {
            height: 250px;
            overflow: hidden;
            position: relative;
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            // border-radius: 3px 3px 0 0;

            &+.card-header {
                .tag-name {
                    margin-top: 0;
                    margin-bottom: 15px;
                    margin-right: 1.25rem;
                }
            }

            img {
                width: 100%;
            }
        }

        .card-header {
            background-color: transparent;
            border-bottom: 0;
            padding: 19px 0 0;

            h4 {
                padding-bottom: 4px;
                margin-bottom: 0;
                position: relative;
                margin: 0;

                a {
                    font-size: 18px;
                    font-weight: 600;
                    line-height: 21px;
                    // letter-spacing: -0.13px;
                    color: var(--col-link-100);
                    position: relative;
                    display: inline-block;
                    margin-right: 25px;
                    text-decoration: none;

                    &::after {
                        content: '';
                        display: block;
                        position: absolute;
                        right: -23px;
                        top: 50%;
                        transform: translateY(-50%);
                        background: url("~src/images/chevron-navy-right.svg");
                        background-repeat: no-repeat;
                        background-size: contain;
                        height: 12px;
                        width: 12px;

                    }

                    &:where(:hover, :focus) {
                        text-decoration: underline;
                    }

                }
            }

            .card-subtitle {
                padding-bottom: 12px;

                cite {
                    font-size: var(--fs-level-7);
                    font-weight: normal;
                    line-height: var(--lh-140);
                    color: var(--col-grey-dark-75);
                    font-style: normal;
                    letter-spacing: 0.105px;
                    word-break: break-all;
                }

            }

            &--wrapper {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
            }

            .tag-name {
                padding: 6px 8px;
                font-size: 14px;
                font-weight: 400;
                letter-spacing: 0.07em;
                color: var(--col-grey-dark-75);
                display: inline-block;
                text-transform: uppercase;
                border: 1px solid var(--col-blue-dark-25);
                border-radius: 2px;
                order: -1;
            }
        }

        .card-body {
            padding-top: 4px;
            padding-bottom: 30px;
            padding-left: 0;
            background-color: transparent;
            // word-break: break-all;

            .card-text,
            .card-text small {
                font-size: 16px;
                font-weight: normal;
                line-height: 24px;
                color: var(--col-grey-dark-100);
                // word-break: break-all;
                letter-spacing: -0.135px;
            }

            .card-subheader {
                color: $grey-mid-1;
                line-height: 24px;
                padding-bottom: 0.5rem;
            }

            p {
                max-width: none;
            }

            .card-text {

                    margin-top: 0.75rem;

                p {
                    margin-inline-start: 0;

                    &:last-child {
                        margin-bottom: 0;
                    }
                }

                a.search-result__card--booknow,
                a {
                    font-size: var(--fs-level-6);
                    font-weight: normal;
                    line-height: normal;
                    letter-spacing: -0.5px;
                    color: var(--col-link-100);
                }

                a.search-result__card--location {
                    font-size: var(--fs-level-7);
                    color: var(--col-link-default);
                    text-transform: uppercase;
                    font-weight: var(--fw-semibold);
                    letter-spacing: 0.98px;
                    margin-bottom: 15px;
                }

                .search-result__card--location {

                    &:hover {
                        text-decoration: none;
                    }
                }

                a.search-result__card--booknow {
                    font-weight: 600;
                    letter-spacing: -0.16px;
                    text-decoration: none;
                    text-transform: none;

                    &:where(:hover, :focus) {
                        text-decoration: underline;
                    }
                }
            }
        }

        &--visited {
            display: block;
            padding-right: 16px;
            font-size: 16px;
            line-height: 24px;
            color: $emerald-dark;

            &:before {
                content: url("~src/images/clock-emerald.svg");
                vertical-align: sub;
                padding-right: 8px;
            }
        }
    }

    .search-exhibit {
        margin-bottom: 34px;
        min-height: 0 !important;
        border-radius: 0;

        .card-header {
            background-color: inherit;
            border: none;
            padding: 0;

            .card-subtitle.card-subtitle-recommended {
                font-size: var(--fs-level-7);
                font-weight: 600;
                line-height: 1.92;
                letter-spacing: -0.1px;
                color: var(--col-grey-dark-100);
                padding-bottom: 0;
            }
        }

        .card-body {
            padding: 0;
            font-size: var(--fs-level-6);
            line-height: 24px;
            // letter-spacing: -0.21px;
            border: none;

            a {
                padding: 0.75rem 1rem;
                border-radius: 4px;
                background-color: var(--col-btn-secondary-bg);
                font-size: 16px;
                font-weight: 700;
                line-height: normal;
                color: var(--col-btn-secondary-text);
                text-decoration: none;

                &:where(:hover, :focus) {
                    background-color: #D5E0D3;
                }
            }
        }
    }

    .search-promo {
        color: $grey-mid-3;

        .card-header {
            display: none;
        }

        .card-title {
            font-family: var(--ff-lead);
            font-size: var(--fs-level-3);
            font-weight: var(--fw-regular);
            color: var(--col-text-primary);
            line-height: 33.6px;
            letter-spacing: -0.48px;
            margin-top: 9px;
            margin-bottom: 28px;
            flex-basis: 100%;
        }

        .card-image {
            padding-bottom: 24px;

            &__wrapper {
                height: 250px;
                overflow: hidden;
                position: relative;
                background-position: center;
                background-repeat: no-repeat;
                background-size: cover;
            }
        }

        .card-body {
            padding-bottom: 22px;

            &--title {
                font-size: var(--fs-level-3);
                font-weight: var(--fw-regular);
                color: var(--col-text-primary);
                line-height: 33.6px;
                letter-spacing: -0.48px;
                padding-bottom: 4px;
                font-family: $font-family-fraunces;
            }
            &--cta {
                text-decoration: none;
                &:hover, 
                &:focus {
                    text-decoration: underline;
                }
            }
            p {
                color: var(--col-grey-dark-100);
                margin-left: 0;
            }

            a {
                font-weight: 600;
                line-height: 24px;
                letter-spacing: -0.01em;
                padding-left: 0;
                position: relative;
                color: var(--col-link-100);

                &::after {
                    content: '';
                    display: block;
                    position: absolute;
                    right: -23px;
                    top: 50%;
                    transform: translateY(-50%);
                    background: url("~src/images/chevron-navy-right.svg");
                    background-repeat: no-repeat;
                    background-size: contain;
                    height: 12px;
                    width: 12px;
                }
            }
        }

        &--2-col {

            .card-body {

                &--title {
                    font-size: var(--fs-level-5);
                    font-weight: var(--fw-semibold);
                    font-family: $font-family-source-sans-pro;
                }

                p {
                    margin-bottom: 8px;
                }

                a {
                    padding-top: 0;
                    padding-bottom: 0;

                    // &::after {
                    //     right: 0;
                    // }
                }

                &+.card-body {
                    padding-top: 10px;
                    padding-bottom: 30px;
                }

            }

        }
    }

    .featured-result:not(.recommended-result) .search-exhibit {
        background-color: #000B34;
        color: white;
        padding: 20px 20px 30px 20px;

        .card-header {

            h4 {
                margin-bottom: 16px;
                margin-inline: 0;

                a {
                    color: white;
                    font-size: 24px;
                    font-weight: 600;
                    letter-spacing: -0.18px;
                    line-height: 28.8px;
                    text-decoration: none;

                    &:where(:hover, :focus) {
                        text-decoration: underline;
                    }
                }
            }
        }

        .card-body {

            p {
                color: white;
                margin-bottom: 34px;
                font-size: var(--fs-level-6);
                line-height: var(--lh-140);
                margin-inline: 0;
                padding-block-end: 0;
            }
        }
    }

    .featured-result.recommended-result {

        .search-exhibit {
            padding-bottom: 0;
            margin-bottom: 0;
        }
    }

    .search-result-default {

        .search-result__card {

            .card-body {

                &:first-child {
                    padding-top: 0;

                    .card-header {
                        padding-top: 0;
                    }
                }

                .card-header {

                    .card-subtitle {
                        padding-bottom: 0;

                        p {
                            // margin-bottom: 0.75rem;
                            padding-bottom: 0;
                        }
                    }
                }
            }
        }
    }

    .search-result-video {
        .search-result__image--wrapper {
            a[target='_blank']:not(.link-icon--disable)::after {
                content: none;
            }
        }

        .card-img-overlay {
            .play-button {
                // background-image: url(../img/play-btn-icon.svg);
                background-image: url("~src/images/play-btn-icon.svg");
                position: absolute;
                width: 52px;
                height: 52px;
                top: calc((100% - 52px) / 2);
                border-radius: 6px;
                left: calc((100% - 52px) / 2);
            }

        }
    }

    .search-result-people {
        .search-result__card {
            // text-align: center;

            .search-result__image--wrapper {
                height: 119px;
                width: 119px;
                margin: 29px auto;
                border-radius: 0;
                margin-bottom: 7px;

                img {
                    background-image: url(~src/images/default-profile-icon.png);
                    // background-image: url(https://res.cloudinary.com/time2hack/image/upload/fa-user.png);
                    background-size: contain;
                    border-radius: 0 !important;
                    background-repeat: no-repeat;
                    background-position: center;
                    margin-top: 0;
                    max-width: 100%;
                    width: auto;
                    min-height: 100%;
                    min-width: 100%;
                    object-fit: cover;
                }

                img::after {
                    content: "";
                    display: block;
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    border: solid 2px white;
                }
            }

            .card-header {

                &--wrapper {
                    padding-bottom: 0;
                }

                h4 {
                    font-size: var(--fs-level-5);
                    font-weight: var(--fw-semibold);
                    color: var(--col-text-primary);
                    line-height: 21px;
                    padding-bottom: 4px;
                }

                .card-subtitle {
                    font-size: var(--fs-level-7);
                    font-weight: var(--fw-regular);
                    line-height: 24px;
                    color: var(--col-grey-dark-75);
                    padding-bottom: 11px;

                    &--job-title {
                        text-transform: uppercase;
                        padding-top: 6px;
                    }
                }
            }

            .card-body {
                // text-align: center;
                padding-top: 0;

                .card-text {

                    .icon {
                        height: 15px;
                    }

                    .search-result__card--mail {
                        text-decoration: underline;
                        font-size: 14px;
                        line-height: 22px;
                        letter-spacing: normal;

                        // &::before {
                        //     content: url("~src/images/envelope.svg");
                        //     vertical-align: sub;
                        //     padding-right: 6px;
                        // }

                        &:focus,
                        &:hover {
                            text-decoration: none;
                        }

                    }

                    &__button {
                        background-color: transparent;
                        // border: 2px solid $blue-300;
                        color: $navy-dark;
                        padding: .4375rem .875rem .5rem 0;
                        // border-radius: 4px;
                        cursor: pointer;
                        display: inline-block;
                        font-weight: 600;
                        // margin: 20px 0 0;
                        font-size: 16px;

                        &::after {
                            content: "";
                            display: inline-block;
                            height: .4em;
                            transform: rotate(-45deg) translateY(-1px);
                            width: .4em;
                            border-bottom: 2px solid $navy-dark;
                            border-right: 2px solid $navy-dark;
                            margin-left: .7em;
                            margin-right: .2em;
                        }
                    }
                }
            }



        }
    }

    .search-result-event {
        .search-result__card {

            &--category,
            &--faculty,
            &--location,
            &--date,
            &--time,
            &--booknow {
                display: inline-block;
                padding-right: 16px;
                font-size: var(--fs-level-7);
                text-transform: uppercase;
            }

            &--category {
                padding-right: 0;
                color: var(--col-grey-dark-100);
                letter-spacing: 0.07em;
            }

            &--faculty {
                position: relative;
                padding-left: 16px;
                display: inline;
                color: var(--col-grey-dark-100);
            }

            &--faculty:before {
                content: url("~src/images/dot-grey.svg");
                // vertical-align: sub;
                position: absolute;
                left: -2px;
                top: 3%;
            }

            &--location {
                display: block;
                padding-bottom: 1rem;
                color: var(--col-text-primary);
                font-weight: var(--fw-semibold);
            }

            // &--location:before {
            //     //   content: url(../img/location-icon.svg);
            //     content: url("~src/images/location-navy.svg");
            //     vertical-align: sub;
            //     padding-right: 8px;
            // }

            &--date,
            &--time {
                padding-bottom: 1rem;
                color: var(--col-text-primary);
                font-weight: var(--fw-semibold);
                letter-spacing: 0.07em;
            }

            // &--date {
            //     .icon {
            //         height: 18px;
            //         vertical-align: text-top;
            //     }
            // }

            &--time {
                display: block;
            }

            // &--date:before {
            //     //   content: url(../img/calendar-icon.svg);
            //     content: url("~src/images/calendar-icon.svg");
            //     vertical-align: sub;
            //     padding-right: 8px;
            // }

            // &--time:before {
            //     content: url("~src/images/clock.svg");
            //     vertical-align: sub;
            //     padding-right: 8px;
            // }

            &--description {
                display: block;
                padding-bottom: 17px;

            }

            &--booknow::after {
                content: '';
                display: inline-block;
                background: url("~src/images/chevron-navy-right.svg");
                width: 12px;
                height: 12px;
                background-size: contain;
                background-repeat: no-repeat;
                margin-left: 1rem;
            }

        }

        a.search-result__card--location {
            text-decoration: underline;

        }

        // span.search-result__card--location {

        //     &::before {
        //         content: url("~src/images/location.svg");
        //     }
        // }
    }

    .search-result-course {

        .search-result__card {

            .card-body {
                padding-top: 0;
                padding-bottom: 0;
                margin-bottom: 0;
            }

            .card-header {
                padding: 0;

                h4 {
                    padding-top: 5px;
                }

                .card-subtitle {
                    color: var(--col-grey-dark-75);
                    font-size: var(--fs-level-7);
                    font-weight: 400;
                    line-height: 24px;
                }

            }

            &--duration,
            &--location {
                display: block;
                padding-right: 16px;
                font-size: var(--fs-level-7);
                color: var(--col-text-primary);
                text-transform: uppercase;
                font-weight: var(--fw-semibold);
                letter-spacing: 0.98px;

            }

            &--duration {
                // display: block;
                padding-bottom: 8px;
            }

            // &--duration:before {
            //   content: url("~src/images/hourglass.svg");
            //   vertical-align: sub;
            //   padding-right: 8px;
            //   padding-left: 5px;
            // }

            &--location {
                // display: block;
                padding-bottom: 5px;

            }

            // &--location:before {
            //   content: url("~src/images/location-navy.svg");
            //   vertical-align: sub;
            //   padding-right: 8px;
            // }


        }

        a.search-result__card--location {
            text-decoration: underline;

            &:hover {
                text-decoration: none;
            }
        }

        // span.search-result__card--location {
        //     &::before {
        //         content: url("~src/images/location.svg");
        //     }
        // }

    }

    // .tag-name {

    //     &--bachelor {
    //         border-left: 6px solid $blue-mid-2 !important;
    //     }

    //     &--degree {
    //         border-left: 6px solid $teal-mid-2 !important;
    //     }

    //     &--diploma {
    //         border-left: 6px solid $blue-mid-1 !important;
    //     }

    //     &--certificate {
    //         border-left: 6px solid $teal-mid-1 !important;
    //     }

    //     &--coursework {
    //         border-left: 6px solid $emerald-mid-1 !important;
    //     }

    //     &--research {
    //         border-left: 6px solid $green-mid-2 !important;
    //     }

    //     &--major {
    //         border-left: 6px solid $purple-mid-2 !important;
    //     }

    //     &--minor {
    //         border-left: 6px solid $purple-mid-1 !important;
    //     }

    //     &--phd {
    //         border-left: 6px solid $green-dark !important;
    //     }

    //     &--specialisation {
    //         border-left: 6px solid $emerald-mid-2 !important;
    //     }

    //     &--interest {
    //         border-left: 6px solid $purple-dark !important;
    //     }

    //     &--pathway {
    //         border-left: 6px solid $teal-mid-2 !important;
    //     }

    //     &--short-course {
    //         border-left: 6px solid $orange-mid-2 !important;
    //     }

    //     &--microcredential {
    //         border-left: 6px solid $orange-mid-2 !important;
    //     }

    //     &--honours {
    //         border-left: 6px solid $green-mid-1 !important;
    //     }

    // }
}


/*
 * Tablet layout
 */


@media screen and (min-width: $tablet) {
    ol.list-unstyled {
        margin-top: 40.5px;


        li.search-result {
            padding-top: 0;
        }

        li.search-result-course {
            padding-top: 1.25rem;
        }

        &--people {
            display: flex;
            flex-wrap: wrap;
            border-top: solid 1px var(--col-grey-dark-25);
            gap: 0.25rem;
            // margin-left: 1.25rem;

            li:not(:first-child) {
                flex-basis: 49%;
            }

            li.search-result-people {
                border-top: none;
                margin-bottom: 0;
            }

            li.search-counts {
                flex-basis: 100% !important;
                margin: 0 !important;

                p {
                    margin-bottom: 4px;
                }
            }

            .search-result__card {

                .card-body {
                    padding-bottom: 0;
                }
            }
        }

    }

    .search-results {
        background-color: #fff !important;

        .search-exhibit {
            padding: 17px 0 40px;

        }

        .search-promo {
            .card-image {

                &__wrapper {
                    background-position: right;
                    background-size: contain;
                }
            }
        }

        .search-result__card.search-promo {
            flex-wrap: nowrap;

            .card-image {
                flex-basis: 100%;
                order: 1;
                padding-bottom: 0;
                margin-bottom: 8px;

            }

            .card-body {
                padding-right: 24px;
                flex-basis: 100%;

                &--title {
                    font-size: 30px;
                    line-height: 42px;
                    letter-spacing: -0.8px;                    
                }

            }

            &.search-promo--2-col {
                flex-wrap: wrap;
                // border-bottom: solid 1px $grey-mid-0;

                .card-title {         
                    line-height: 42px;
                    letter-spacing: -0.8px;
                }

                .card-body {
                    flex-basis: 50%;
                    padding-top: 0;
                    padding-bottom: 0;
                    padding-right: 24px;

                    &--title {                        
                        font-size: 24px;
                    }

                    &:last-of-type {
                        padding-right: 0;
                        // padding-left: 12px;
                    }

                }
            }
        }

        .recommended-result {

            .search-result__card {

                &--location,
                &--duration {
                    display: inline-block;
                }
            }

        }


        // .search-refine {

        //     .card-header {
        //     padding-bottom: 0;
        //     margin-bottom: 0;

        //     }
        // }

        &__wrapper {
            li.search-tier {
                margin-bottom: 0;
            }
        }

        .list-inline {

            .list-inline-item {
                display: inline;

                .badge {
                    margin-bottom: 12px;
                }
            }
        }

        div#toggle-search-vis {
            padding-left: 15px;
        }

        .search-counts+.search-result,
        .search-tier+.search-result,
        .search-result:first-child {
            .search-result__card .card-header {
                border-top: 0;
            }
        }

        .card-header {
            // background-color: white;
            border-bottom: 0;
            // padding-bottom: 21px;
        }

        .search-result__card {
            display: flex;
            flex-wrap: wrap;
            flex-direction: row;
            box-shadow: none;
            width: 100%;
            // border-bottom: solid 1px $grey-300;

            .search-result__image--wrapper {
                order: 1;
                width: 226px;
                height: 200px;
                margin-right: 0;
                border-radius: 0;
                margin-left: 22.5px;
                margin-bottom: 1.25rem;
                margin-top: 1.25rem;
            }

            .card-header {
                flex: 0 0 100%;
                padding-right: 0;
                padding-bottom: 0;
                padding-left: 0;
                border-bottom: 0;

                h4 {

                    a {
                        font-size: 24.33px;
                        line-height: 27px;
                        margin-right: 28px;

                    }

                }

                .card-subtitle {
                    font-size: 15px;
                    line-height: 1.67;
                    color: $grey-600;
                    padding-bottom: 8px;
                }

                .tag-name {
                    margin-bottom: 8px;
                    margin-top: 0;
                    height: 25px;
                    line-height: 25px;
                    padding: 0 9px;
                }
            }

            .card-body {
                flex: 1;
                padding: 0;
                padding-top: 1px;
                padding-bottom: 1.25rem;

                // .card-subheader {
                //     margin-bottom: 6px;
                // }
            }
        }

        .search-result-default {

            .search-result__card {

                .card-body {

                    &:first-child {

                        .card-header {
                            padding-top: 19px;
                        }
                    }

                }
            }
        }

        .search-result-people {

            .search-result__card {
                flex-direction: column;
                text-align: left;
                padding: 20px 0;
                // border-bottom: solid 1px $grey-300;
                min-height: 159px;
                height: 100%;
                flex-wrap: nowrap;

                .search-result__image--wrapper {
                    position: absolute;
                    margin: 0 auto;
                }

                .card-header {
                    // width: calc(100% - 125px);
                    // align-self: flex-end;
                    padding-top: 3px;
                    margin-left: 0;
                    margin-bottom: 0;
                    // padding-left: 1.25rem;
                    border-top: 0;
                    flex: 0 1 auto;

                    &--wrapper {
                        flex-direction: column;
                    }

                    h4 {
                        font-size: 24.33px;
                        line-height: 27px;

                    }

                    h4,
                    .card-subtitle {
                        text-align: left;
                        padding-bottom: 3px;
                    }
                }

                .card-body {
                    flex: 0 1 auto;
                    display: flex;
                    flex-direction: column;
                    flex-wrap: nowrap;
                    height: 100%;
                    padding-bottom: 0;

                    .card-text {
                        margin-bottom: 13px;

                        &__button {
                            margin-top: auto;
                        }

                    }
                }
            }
        }

        li.search-result-event {            
            padding-top: 0;

            .search-result__card {
                &--location,
                &--time {
                    display: inline-block;
                }

            }

        }

        li.search-result-course {
            padding-top: 1.25rem;
            margin-bottom: 0;

            .search-result__card {

                &--location,
                &--duration {
                    display: inline-block;
                }

                &--location {
                    padding-bottom: 13px;
                    margin-bottom: 7px !important;
                }
            }
        }

        .card-subtitle-all-results {
            margin-bottom: -22px;
        }

        .list-unstyled--people {

            .search-result-people {

                .search-result__card {

                    h4 {
                        font-size: 18.14px;
                        line-height: 21px;
                    }
                }

            }

        }
    }

}

/*
 * Desktop layout
 */

@media screen and (min-width: $desktop) {

    ol.list-unstyled {
        margin-left: 1.25rem;
        margin-bottom: 2rem;

        &--people {
            margin-left: 1.25rem;
            margin-bottom: .5rem;

            li:not(:first-child) {
                flex-basis: 49%;
            }
        }
    }

    .search-results {
        font-size: var(--fs-level-6);

        .featured-result.recommended-result {
            margin-left: 1.25rem;
        }

        .search-result__card.search-promo {
            margin-left: 0;

            &.search-promo--2-col {
                margin-bottom: 68px;
            }
        }

        .search-result__card {

            .card-header {

                h4 {

                    a {
                        margin-right: 45px;
                    }
                }
            }

            .card-body {

                .card-text {
                    font-size: var(--fs-level-6);
                }

            }

            
        }

        li.search-result {

            &.search-result-people {
                margin-left: 1.25rem;
            }
        }

        .list-inline {
            padding-left: 1.25rem;
            padding-top: 0;
        }

        .search-counts {
            margin-bottom: 0;
            margin-left: 0;
            margin-right: 0;
            font-size: var(--fs-level-6);
    
            .col-md-12.mt-2 {
                padding-left: 1.25rem;
            }
        }


    }

    .search-tier {
        margin-bottom: 0;

        h3 {
            padding-left: 10px;
        }
    }

    div#toggle-search-vis {
        padding-left: 7px;
    }

    

    .search-counts.mb-3.pt-3 p {
        // padding-left: 10px;
    }

    .search-exhibit {
        padding-left: 20px;
        padding-right: 20px;
    }

    .featured-result:not(.recommended-result) .search-exhibit {
        padding: 16px 34px 48px 20px;
        margin-bottom: 39px;

        .card-header {
            margin-bottom: 0px;
        }

        .card-body {
            p {
                margin-bottom: 46px;
            }
        }
    }

    .search-results .featured-result.recommended-result .search-exhibit {
        // margin-left: 1.25rem;
        padding-left: 0;
    }

    .search-sort__wrapper {

        button {
            margin-bottom: 0;
        }
    }


    .recommended-result {
        .search-result__card {

            .card-body,
            .card-header {
                margin-left: 0;
            }

            .card-body {
                padding-bottom: 0;
            }
        }

        .recommended-result {
            margin-left: 41px;
        }

        .card-subtitle.card-subtitle-all-results {
            margin-left: 41px;
        }
    }

    li.search-result-event {
        // margin-bottom: 28px;
    }

    ol.list-unstyled--people {
        li.search-result {
            &.search-result-people {
                margin-left: 0;
            }
        }
    }
}
