/*
 * Mobile layout
 */

.search-related {
    border: none;
    background-color: var(--col-grey-light-25);
    border-radius: 0;
    padding-top: 1.25rem;
    padding-bottom: 1rem;
    position: relative;

    .card-title {
        margin: 1rem 0;
        padding-inline-start: 0;

        h3 {
            // font-family: var(--ff-lead);
            font-weight: var(--fw-semibold);
            font-size: var(--fs-level-4);
            line-height: 33.6px;
            letter-spacing: -0.45px;
            color: var(--col-text-primary);
            margin: 0;
        }

    }

    .grid {
        gap: 0;
        margin-inline: 0;
    }

    .cell {
        margin-top: 0.5rem;
    }

    ul.search-related-keyword {
        padding: 0;

        li.search-related-keyword-item {
            margin: 0;
            padding-block-end: 1rem;
        }

    }

    

    &-keyword {

        &-item {
            margin-bottom: 1rem;

            &:last-of-type {
                margin-bottom: 0;
            }
        }
        

        a {
            color: var(--col-link-default);
            font-weight: 400;
            line-height: 24px;
            letter-spacing: -0.135px;
            text-decoration: underline;
            font-weight: var(--fw-semibold);

            strong{
                font-weight: var(--fw-bold);
            }

            &:focus,
            &:hover {
                text-decoration: none;
            }
        }

    }



}


/*
 * Tablet layout
 */

@media screen and (min-width: $tablet) {
    .search-related {

        .cell {
            width: 50%;
        }

        &-keyword {    
            a {
                font-size: var(--fs-level-6);
            }
    
        }
    }
}


/*
 * Desktop layout
 */

@media screen and (min-width: $desktop) {
    .search-related {
        padding-top: 3.75rem;
        padding-bottom: 3.75rem;

        .card-title {
            border-right: 1px solid var(--col-grey-light-50);
            padding-inline-start: 0.9375rem;
        }

        .card-text {
            padding-left: 3rem;
        }

        .cell {
            margin: 0;
            margin-top: 1rem;
        }
    }
}
