/*
 * Mobile layout
 */

.search-facets__wrapper {
    padding: 16px 15px 0;
    background-color: #fff;
    top: 0;
    min-height: 100vh;
    width: 100%;
    z-index: 1000;
    left: 0;
    position: fixed;
    // height: auto;
    // z-index: 0;
    height: 100vh;
    overflow-y: auto;

    .search-facets__close {
        outline: 0;
        height: 65px;
        // width: 65px;
        border: none;
        // position: absolute;
        // top: 0;
        // right: 0;
        // padding: 0;
        display: block;
        cursor: pointer;
        margin-left: auto;
        margin-right: 0;
        padding-right: 1.5rem;
        background-color: #fff;
        background-image: url(~src/images/close-navy.svg);
        background-size: 0.9rem;
        background-repeat: no-repeat;
        background-position: 90% 50%;
        color: #083973;
        font-size: 0.9rem;

    

        // &::before {
        //     content: "";
        //     height: 3px;
        //     width: 24px;
        //     position: absolute;
        //     background: black;
        //     left: 50%;
        //     top: 50%;
        //     transform: translateX(-50%) rotate(45deg);
        // }

        // &::after {
        //     content: "";
        //     height: 3px;
        //     width: 24px;
        //     position: absolute;
        //     background: black;
        //     left: 50%;
        //     top: 50%;
        //     transform: translateX(-50%) rotate(-45deg);
        // }
    }

    .search-facets {
        padding: 0;
        border-radius: 2px;
        // box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.3);

        .badge-default {
            --badge-fs: var(--fs-default);

            // border-radius: 9px;
            // background-color: #e7e7e7;
            // padding: 0 9px;
            font-size: var(--badge-fs);
            color: var(--col-grey-dark-75);
            font-weight: 400;
            // height: 17px;
            line-height: 21px;
            background-color: transparent;
            padding: 0;
            padding-left: 4px;
            letter-spacing: -0.12px;
        }
    }

    .card.search-refine {
        border: none;
    }

    .card.search-facet {
        border: none;
    }

    .card-header {
        padding: 0;
        border: none;
        margin-bottom: 16px;
        background-color: #fff;

        h3 {
            font-size: var(--fs-level-5);
            font-family: $font-family-source-sans-pro;
            font-weight: 600;
            color: var(--col-text-primary);
            margin: 0;

        }
    }
    .card.search-facett {
        .card-body {
            margin: 0;
        }
    }
    
    .card-body {
        padding: 12px 0 0 0;
        border-top: solid 1px var(--col-grey-light-50);
        position: relative;

        .clear-all.float-right {
            // margin-top: 16px;
            margin-right: 6px;
            font-size: 0.875rem;
            line-height: 17.6px;
            font-weight: 400;
            color: var(--col-text-primary);
            position: relative;
            z-index: 1;

            .clear-all-text {
                font-size: var(--fs-level-7);
                line-height: var(--lh-140);
                font-weight: var(--fw-bold);
                color: var(--col-link-100);
                text-transform: capitalize;
                letter-spacing: 0.105px;

            }
        }

        .card-title {
            position: relative;

            h4 {
                font-size: var(--fs-level-6);
                font-weight: 700;
                line-height: 24px;
                // letter-spacing: -0.45px;
                color: var(--col-text-primary);
                border: none;
                padding-bottom: 6px;
                text-transform: capitalize;
                margin-inline: 0;

                // &:focus,
                // &:hover {
                //     cursor: pointer;
                // }

                // &:after {
                //     // content: url(../img/chevron.svg);
                //     content: url("~src/images/chevron.svg");
                //     width: 1em;
                //     height: 1em;
                //     text-align: center;
                //     transform: rotate(0deg);
                //     transform-origin: bottom;
                //     transition: all .35s;
                //     position: absolute;
                //     right: 0;
                //     top: calc(50% - 15px);
                // }
            }
        }

        .card-title.close-facet {
            margin-bottom: 0;

            & + ul {
                height: 0;
                overflow: hidden;
                margin: 0;

                + button {
                    height: 0;
                    visibility: hidden;
                    padding: 0;
                }

                + .clear-all.float-right {
                    display: none;

                    + button {
                        height: 0;
                        visibility: hidden;
                        padding: 0;
                    }
                }
            }

            h4 {
                margin-bottom: 0;
                padding-bottom: 0;

                // &:after {
                //     transform: rotate(180deg);
                // }
            }
        }

        ul.list-unstyled {
            padding: 0;
            margin: 7px 2px 0;
        }

        li {
            --tickbox-fs : var(--fs-base-200);

            position: relative;
            margin-bottom: 1.5rem;
            line-height: 17px;
            padding: 0;
            margin-inline: 0;

            &:last-child {
                margin-bottom: 1.75rem;
            }

            a {
                font-size: var(--tickbox-fs);
                // letter-spacing: -0.21px;
                color: var(--col-text-secondary);
                padding-left: 22px;
                font-weight: normal;
                // width: calc(100% - 50px);
                display: inline-block;
                text-transform: capitalize;
                letter-spacing: var(--tickbox-ls);
                line-height: var(--lh-140);
                text-decoration: none;

                &:hover {
                    text-decoration: underline;
                }

                &:before {
                    content: '';
                    position: absolute;
                    left: 0;
                    width: 16px;
                    height: 16px;
                    border-radius: 1px;
                    border: var(--tickbox-border, solid 1px $grey-mid-1);
                    background: var(--tickbox-background, transparent);
                    top: 50%;
                    transform: translateY(-50%);
                    border-radius: 2px;
                }
                &[aria-checked="false"] span:before {
                    display: none;
                }

                &[aria-checked="true"] {
                    --tickbox-border: 1px solid var(--col-heritage-100);
                    --tickbox-background: var(--col-heritage-100);

                    span:after {                        
                        content: "";
                        position: absolute;
                        background: url(~src/images/tick-teal.svg);
                        left: 2px;
                        top: 50%;
                        transform: translateY(-50%);
                        display: block;
                        background-repeat: no-repeat;
                        background-size: contain;
                        width: 0.75rem;
                        height: 0.75rem;
    
                    }

                } 
            }

            [role="checkbox"]{
                cursor: pointer;
                font-size: var(--tickbox-fs);
                // letter-spacing: -0.21px;
                color: var(--col-text-secondary);
                padding-left: 22px;
                font-weight: normal;
                // width: calc(100% - 65px);
                display: inline-block;
                text-transform: capitalize;
                line-height: var(--lh-140);
                letter-spacing: var(--tickbox-ls);

                &:hover{
                    text-decoration: underline  ;
                }

                &:before {
                    content: '';
                    position: absolute;
                    left: 0;
                    width: 16px;
                    height: 16px;
                    border-radius: 1px;
                    border: var(--tickbox-border, solid 1px $grey-mid-1);
                    background: var(--tickbox-background, transparent);
                    top: 50%;
                    transform: translateY(-50%);
                    border-radius: 2px;
                }

                &[aria-checked="false"] span:before {
                    display: none;
                }

                &[aria-checked="true"] {
                    --tickbox-border: 1px solid var(--col-heritage-100);
                    --tickbox-background: var(--col-heritage-100);

                    span:after {                        
                        content: "";
                        position: absolute;
                        background: url(~src/images/tick-teal.svg);
                        left: 2px;
                        top: 50%;
                        transform: translateY(-50%);
                        display: block;
                        background-repeat: no-repeat;
                        background-size: contain;
                        width: 0.75rem;
                        height: 0.75rem;
    
                    }

                } 

                // &[aria-checked="true"] span:after {
                //     transform: translateX(-50%) rotate(-45deg);
                // }

                // &[aria-checked="true"] span:before {
                //     transform: translateX(-50%) rotate(45deg);
                // }
            }

            .card-list-text {
                font-size: var(--fs-base-300);
                letter-spacing: -0.135px;
            }

            .badge {
                font-size: var(--fs-base-100);
                letter-spacing: -0.105px;
            }

        }
    }

    button.search-toggle-more-categories {
        padding-top: 14px;
        border: none;
        padding-left: 0;
        text-align: left;
        width: 70%;
        color: var(--col-text-primary);

        span {
            font-size: 13px;
            font-weight: 600;
            letter-spacing: -0.39px;
            color: $blue-400;
            text-transform: uppercase;
        }

        &:hover {
            text-decoration: underline;
        }
    }

}

/*
 * Desktop layout
 */
 @media screen and (min-width: $desktop) {

    .search-facets__wrapper.hidden,
    .page-footer.hidden,
    #toggle-search-vis.hidden {
        display: block!important;
        visibility: visible!important;
    }

    .search-facet__button {
        display: none!important;
    }

    .search-facets__wrapper {
        min-height: auto;
        width: auto;
        position: relative;
        padding-top: 0;
        width: 261px;
        z-index: 0;
        border-right: 1px solid var(--col-grey-light-50);
        padding-right: 33px;
        overflow-y: visible;
        height: auto;

        // padding-left: 15px;


        .search-facets {
            box-shadow: none;
            border-radius: 0;

            .badge-default {
                --badge-fs: var(--fs-base-100);
            }
        }

        .card.search-facet:last-child {

            .card-body {
                border-bottom: 0;
            }

        }

        .search-facets__close {
            display: none!important;
        }

        .feature-facet + .card-header {
            padding-top: 39px;
        }

        .search-refine .card-header{
            padding: 0;
            margin-bottom: 1rem;

        }

        .card.search-refine {
            min-height: auto;
        }

        .card-body {
            padding: 0;
            padding-top: 12px;

            .float-right.clear-all {
                position: absolute;
                top: 16px;
                right: 0;
                margin-top: 0;
            }

            .card-title {
                margin-bottom: 0;
                width: 180px;

                h4 {
                    padding-bottom: 5px;

                }
            }

            .card-title.close-facet {
                margin-bottom: .75rem;

                h4 {
                    margin-bottom: 6px;
                    padding-bottom: 6px;
                }

                & + ul {
                    height: auto;
                    overflow: auto;
                    margin-bottom: 2px;

                    & + button {
                        height: auto;
                        visibility: visible;
                        padding: .25rem .5rem;
                    }
                }
            }

            li {
                --tickbox-fs: var(--fs-base-300);
                --tickbox-ls: -0.135px;

                margin-bottom: 16px;

                &:last-child {
                    margin-bottom: 0;
                }
            }

        }

    }
 }

//  @media screen and (min-width: 1360px) {
//     .search-facets__wrapper .card-body li [role="checkbox"][aria-checked="true"] span:after {
//         left: -10px;
//     }

//     .search-facets__wrapper .card-body li a span.facet-selected:after {
//         left: -10px;
//     }

//  }