// /*
//  * Mobile layout
//  */

 nav {
    a:hover {
        text-decoration: none;
    }
}

// .search-header__wrapper.page-header  {
//     img.logo--no-padding-md {
//         margin: 0;
//     }
// }


// /*
//  * Tablet layout
//  */

// @media screen and (min-width: $tablet){
//     .search-header__wrapper {

//     }
// }


// /*
//  * Desktop layout
//  */

// @media screen and (min-width: $desktop){
//     .search-header__wrapper {
        
//     }
// }

// /*
//  * UltraWide layout
//  */

// @media screen and (min-width: 1360px) {

//     .page-header--study .page-header__inner {
//         min-height: 108px;
//         padding-left: 18px;
//     }

//     .logo__image {
//         height: 95.9844px;
//         width: 95.9844px;
//     }

//     .page-title {
//         font-size: 19.98px;
//     }

//     .megamenu__blanket {
        
//         .mega-top {
//             padding-left: 31.5px;
//             padding-right: 31.5px;
//             padding-top: 9px;

//             li {
//                 font-size: 18px;
//                 line-height: 27px;
//             }
//         }

//         .menu-top__item {
//             font-size: 16.2px;
//         }
//     } 

//     .megamenu {

//         .menu__section {

//             .menu__item {
//                 padding: 4.5px;
//             }

//             .menu__link {
//                 font-size: 18px;
//                 padding-left: 11.25px;
//                 padding-right: 11.25px;
//             }
//         }
//     } 

//     .link-icon--vertical .link-icon__icon {
//         width: 27px;
//         height: 27px;
//     }
// }