/*
 * Mobile layout
 */

.search-banner__wrapper.jumbotron {
    min-height: 130px;
    background-color: var(--col-bg-primary);

    .container {
        padding-right: 15px;
        padding-left: 15px;
        padding-top: 20px;
        padding-bottom: 10px;
        flex-direction: column;

        h2 {
            font-size: var(--fs-base-500);
            color: white;
            font-weight: var(--fs-level-5);
            line-height: var(--lh-120);
            font-family: var(--ff-lead);
            align-self: flex-start;
            letter-spacing: -1.08px;
            margin-inline: 0;
            padding-block-end: 0;
            margin-block-end: 0.5rem;
        }

        .notice a {
            color: black;
            text-decoration: none;

            &:hover {
                color: #F9423A;
                text-decoration: underline;
            }
        }

        .row {
            justify-content: center;
        }

        input {
            border: none;
            margin-block-start: 0;
            &:focus {
                    outline: 5px auto highlight;
                    outline: 5px auto -webkit-focus-ring-color;
                
            }
        }

        [type="search"] {
            &:focus {
                outline-offset: 0;
            }

        }
    }



    .input-group-text {
        position: absolute;
        right: 0;
        width: 48px;
        height: 100%;
        padding: 0;
        bottom: 0;
    }

    .input-group {
        background-color: white;
        border-radius: 4px;
        border: none;
        // padding-left: 0.75rem;
    }

    button {
        background-color: var(--col-btn-primary-bg);
        height: 100%;
        background-image: url("~src/images/search-icon-blue.svg");
        background-position: center;
        background-repeat: no-repeat;
        // background-size: 17px;
        border-radius: 0 4px 4px 0;

        &:hover,
        &:focus {
            background-color: var(--col-btn-primary-bg-hover);
        }
    }

    input {
        font-size: 22px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: -0.19px;
        color: #000F46;
        padding-left: 0.75rem;
        padding-bottom: 9px;
        padding-top: 9px;
        width: calc(100% - 48px);

        &::placeholder {
            font-size: 16px;
            line-height: 1.5;

        }
    }
}

/*
 * Tablet Layout
 */

@media screen and (min-width: $tablet) {
    .search-banner__wrapper.jumbotron {
        padding-top: 0px;
        padding-bottom: 0px;

        .container {
            max-width: 63rem;

            h2 {
                font-size: 2rem;
                font-size: var(--fs-base-600);
            }
        }

        .input-group-text {
            right: 0px;
            bottom: 0px;
            height: 100%;
        }

    }
}

/*
 * Desktop layout
 */

@media screen and (min-width: $desktop) {
    .search-banner__wrapper.jumbotron {
        min-height: 243px;

        .container {
            padding-top: 44px;
            padding-left: 1.5rem;
            padding-right: 1.5rem;

            h2 {
                font-size: var(--fs-base-800);
                line-height: 3.375rem;
                margin-bottom: 20px;
            }
        }

        .input-group {
            
            border-radius: 4px;
            margin: auto;
        }

    }
}

/*
 * Ultra Wide layout
 */

 @media screen and (min-width: 1360px) {
    .search-banner__wrapper.jumbotron {

        .container {
            max-width: 1134px;
            padding-left: 27px;
            padding-right: 27px;

        }

        input {
            font-size: 18px;
            padding-block: 0.75rem;
    
            &::placeholder {
                font-size: 18px;    
            }
        }
    }
}