/*
 * Mobile layout
 */

.search-banner__wrapper {
    .twitter-typeahead {
        flex-grow: 2;
        width: 100%;
        height: 48px;
    }

    .tt-dataset-organic {
        width: 25%;

        .tt-suggestion {
            font-weight: bold;
        }
        .tt-highlight {
            font-weight: normal;
        }
    }

    .tt-dataset-people {
        width: 40%;

        img {
            max-width: 64px;
            max-height: 64px;
        }
    }

    .tt-dataset-courses {
        width: 35%;
    }

    .tt-dataset-courses,
    .tt-dataset-people {

        .tt-suggestion {
            padding-right: 25px;
        }

        .tt-selectable {
            position: relative;

            &::after {
                content: '';
                display: block;
                position: absolute;
                right: 0;
                top: 6px;
                // transform: translateY(-50%);
                background: url("~src/images/chevron-right--teal.svg");
                background-repeat: no-repeat;
                background-size: contain;
                height: 12px;
                width: 12px;
            }

            .tt-title {
                
            }

            .details {
                font-size: 14px;
                font-weight: 400;
                line-height: 21px;
                color: $brand-light-2;
                text-transform: capitalize;
            }

            &:hover,
            &:focus {

                .tt-title {
                    text-decoration: underline;
                    color: var(--col-text-tertiary);
                }
            }

        }

    }

    .tt-menu {
        width: 100vw;
        background: var(--col-bg-secondary);
        margin-top: 25px;
        left: calc((100vw - 100%) / -2) !important;
        padding-left: calc((100vw - 100%) / 2);
        padding-right: calc((100vw - 100%) / 2);
        padding-top: 35px;
        padding-bottom: 89px;
        color: var(--col-text-light);
        box-shadow: 1px 6px 12px -3px rgba(0, 0, 0, 0.6);

        .tt-dataset {
            float: left;
            display: table-cell;
            height: 100%;

            &.tt-dataset-organic,
            &.tt-dataset-people,
            &.tt-dataset-courses {
                width: 100%;
            }

            h5 {
                font-size: 18.15px;
                font-weight: var(--fw-semibold);
                line-height: 21px;
                text-transform: none;
                margin-right: 30px;
                margin-bottom: 12px;
                margin-top: 35px;
                letter-spacing: -0.75%;
                color: var(--col-text-light);

            }

            h6 {
                color: #9a1904;
                margin: 0;
            }

            &:first-child {

                h5 {
                    margin-top: 0;
                }
            }

            .tt-suggestion {
                cursor: pointer;
            }

            .tt-suggestion {
                margin: 0.75rem 0;

                &:first-of-type {
                    margin-top: 0;
                }

                &:last-of-type {
                    margin-bottom: 0;
                }
            }

            &-organic {

                .tt-suggestion {
                    line-height: 28px;
                    text-decoration: underline;

                    &:hover,
                    &:focus {
                        text-decoration: none;
                        color: var(--col-text-tertiary);
                    }


                }

            }

            &-courses {

                .tt-selectable {
                    line-height: 24px;
                    font-weight: 600;

                    
                }
            }

        }

        .tt-dataset-people {

            .tt-suggestion.tt-selectable {

                img {
                    background-image: url(~src/images/default-profile-icon.png);
                    // background-image: url(https://res.cloudinary.com/time2hack/image/upload/fa-user.png);
                    background-size: contain;
                    background-repeat: no-repeat;
                    background-position: center;
                    object-fit: cover;
                    width: 42px;
                    height: 42px;
                    position: relative;
                    margin-right: 25px;
                    margin-top: 3px;

                    &:after {
                        content: "";
                        display: block;
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        border: solid 1px var(--col-text-light);
                    }
                }

                h6 {
                    padding-bottom: 6px;
                    color: inherit;
                    margin: inherit;
                }
            }
        }

        .tt-suggestion.tt-cursor {
            background-color: $bg-white;
        }

    }
}

@media screen and (min-width: $tablet) {
    .search-banner__wrapper {

        .tt-menu {
            

            .tt-dataset {
                padding: 0 25px;

                &:first-of-type {
                    padding-left: 0;
                }

                &:last-of-type {
                    padding-right: 0;
                }

                h5 {
                    margin-top: 0;
                }

                &.tt-dataset-organic,
                &.tt-dataset-people,
                &.tt-dataset-courses {
                    width: 33%;
                }
            }

        }
    }

}

@media screen and (min-width: $desktop) {
    
    .search-banner__wrapper {

        .tt-menu {
            margin-block-start: 75px;
            
            .tt-dataset {

                &-organic {
                    .tt-selectable {
                        font-size: var(--fs-level-6);
                    }
                }

                &-courses {
                    .tt-title {
                        font-size: 20px;
                    }

                    .details {
                        font-size: 16px;
                    }
                }

                &-people {
                    .tt-title {
                        font-size: 20px;
                    }

                    .details {
                        font-size: 16px;
                    }
                }

                .tt-suggestion {
                    margin-bottom: 20px;
                }
                
                h5 {
                    font-size: 24px;
                    margin-bottom: 25px;
                }
            }
        }
    }

    .search-banner__wrapper .tt-menu {
        padding-left: calc((100vw - 1110px) / 2);
        padding-right: calc((100vw - 1110px) / 2);
    }

}
