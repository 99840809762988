// Screen Hidden Content
.visuallyhidden {
    @extend %screen-hide;
}

// When screen hidden content is focused restore it to view
.visuallyhidden.focusable:active,
.visuallyhidden.focusable:focus {
    @extend %screen-show;
}

// Bootstrap friendly class
.sr-only {
    @extend .visuallyhidden;
}

// Clear fix class for containers of floated elements
.clearfix {
    @extend %clearfix;
}

// Hide from both screenreaders and browsers: h5bp.com/u
.hidden {
    display: none !important;
    visibility: hidden;
}

// Hide visually and from screenreaders, but maintain layout
.invisible {
    visibility: hidden;
}

// Float classes
.pull-left {
    float: left;
}
img.pull-left {
    margin-right: 1em;
}

.pull-right {
    float: right;
}
img.pull-right {
    margin-left: 1em;
}

// 16:9 Ratio container for embedded video
// This container is suitable for something like youtube videos
.video-container {
    position: relative;
    padding-bottom: 56.25%; // Video area
    padding-top: 30px; // 30px controls container
    height: 0;
    overflow: hidden;
    margin-bottom: 1em;
}

.video-container iframe,
.video-container object,
.video-container embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.grid:not(footer .grid) {
    display: flex;
    margin-right: -0.75rem;
    margin-left: -0.75rem;
    flex-direction: column;

    @media screen and (min-width: $tablet) {
        flex-direction: row;
        flex-wrap: wrap;
    }
}

.list-reset {
    margin-block-end: 0;
    padding-inline-start: 0;
    list-style-type: none;
}

.max {
    max-width: 63rem;
    margin-inline: auto;
    padding-inline: 1.5rem;

    @media screen and (min-width: 1360px) {
        max-width: 70.875rem;
        padding-inline: 1.6875rem;
    }
}